import Tracking from '~/utils/Tracking'

export default ( { app, route, store }, inject ) => {
  const tracking = new Tracking( app, route )
  // For some routes we do not want tracking.
  if (
    !store.state.whitelabel &&
    !route.path.startsWith( '/cc-new' )
  ) {
    // Check for non-mandatory consent
    window.addEventListener( 'CookiebotOnAccept', function ( _e ) {
      const consents = []
      for ( const [key] of Object.entries( window.Cookiebot.consent ) ) {
        if (
          window.Cookiebot.consent[key] &&
          key !== 'stamp' &&
          key !== 'necessary'
        ) {
          consents.push( Tracking[key.toUpperCase()] )
        }
      }
      tracking.load( consents )

      // Clear tracking queue
      Object.keys( tracking.queue ).forEach( ( queueKey ) => {
        tracking.queue[queueKey].forEach( ( func ) => {
          // If func is array it means it is a function that takes arguments
          if ( Array.isArray( func ) ) {
            // Map app into this context
            this.app = app
            func[0].apply( this, [func[1]] )
          }
          // Its function that does not take arguments
          else {
            func.call( tracking )
          }
        } )
      } )
    }, false )

    // run tracking on consented-to platforms
    tracking.load( [Tracking.NECESSARY] )

    // Init onPageView every time the route changes (fired on initialization too)
    app.router.afterEach( ( to, from ) => {
      tracking.onPageView( to, from )
    } )
  }
  else {
    tracking.disable()
  }

  // Inject tracking into vue app and context
  inject( 'tracking', tracking )
}
