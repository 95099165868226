<template>
  <div>
    <navbar />
    <nuxt-child :site-name="name" />
    <section v-if="$route.path === '/'" class="section is-small">
      <div class="container">
        <loan-application />
      </div>
    </section>
    <lazy-hydrate ssr-only>
      <footer-comp logo="footer_logo.png" />
    </lazy-hydrate>
  </div>
</template>

<script>
import LazyHydrate from 'vue-lazy-hydration'

export default {
  layout: 'whitelabel',
  components: {
    LazyHydrate,
    footerComp: () => import( '~/whitelabels/components/footer' ),
    loanApplication: () => import( '~/components/loanApplication' ),
    navbar: () => import( '~/whitelabels/components/navbar' )
  },
  head () {
    const desc = 'Smartlaina on uusi netissä toimiva suomalainen palvelu, joka auttaa löytämään kulutusluottoja jopa 60 000 € asti.'
    const theme = '#6d072f'
    return {
      title: `${this.name} | Lainahakemus`,
      meta: [
        { hid: 'description', name: 'description', content: desc },
        { hid: 'og:title', name: 'og:title', property: 'og:title', content: this.name },
        { hid: 'og:site_name', name: 'og:site_name', property: 'og:site_name', content: this.name },
        { hid: 'og:description', name: 'og:description', property: 'og:description', content: desc },
        { hid: 'apple-mobile-web-app-title', name: 'apple-mobile-web-app-title', content: this.name },
        { hid: 'theme-color', name: 'theme-color', content: theme }
      ],
      link: [{ rel: 'icon', type: 'image/x-icon', href: require( '~/assets/whitelabels/smartlainat/favicon.png' ) }]
    }
  },
  data () {
    return {
      name: 'SMARTlainat',
      domain: 'smartlainat.fi'
    }
  }
}
</script>

<style lang="scss">
@import "~assets/whitelabels/scss/_variables.scss";

$wl-primary: #6d072f;
$wl-body-background: url('~assets/whitelabels/smartlainat/bg_pattern.jpg');
$wl-navbar-background: url('~assets/whitelabels/smartlainat/header_bg.png');

$wl-footer-color: white;
$wl-footer-background: #000d29;

@import "~assets/whitelabels/scss/styles.scss";

.whitelabel.smartlainat {
  @include whitelabel;
}
</style>
