import { checkEligble, expName, expID } from '~/utils/samblaRedirect'

export default {
  name: expName,
  experimentID: expID,
  isEligible: ( context ) => {
    const isEligible = checkEligble( context )
    return isEligible
  },
  variants: [
    { weight: 95 },
    { weight: 5 }
  ]
}
