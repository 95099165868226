import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _7c095558 = () => interopDefault(import('../pages/application-stored.vue' /* webpackChunkName: "pages/application-stored" */))
const _32ad56e0 = () => interopDefault(import('../pages/autolaina.vue' /* webpackChunkName: "pages/autolaina" */))
const _553c4874 = () => interopDefault(import('../pages/autorahoitus.vue' /* webpackChunkName: "pages/autorahoitus" */))
const _488b1be4 = () => interopDefault(import('../pages/cc-new/index.vue' /* webpackChunkName: "pages/cc-new/index" */))
const _57fa3284 = () => interopDefault(import('../pages/cc-new/index/kuluttaja.vue' /* webpackChunkName: "pages/cc-new/index/kuluttaja" */))
const _7502acef = () => interopDefault(import('../pages/cc-new/index/yritys.vue' /* webpackChunkName: "pages/cc-new/index/yritys" */))
const _5a0d4230 = () => interopDefault(import('../pages/etutuoterahoitus.vue' /* webpackChunkName: "pages/etutuoterahoitus" */))
const _bded8810 = () => interopDefault(import('../pages/identiteettivakuutus.vue' /* webpackChunkName: "pages/identiteettivakuutus" */))
const _70ab573c = () => interopDefault(import('../pages/iframe.vue' /* webpackChunkName: "pages/iframe" */))
const _4b916590 = () => interopDefault(import('../pages/iframe/leadgroup.vue' /* webpackChunkName: "pages/iframe/leadgroup" */))
const _6ce089d8 = () => interopDefault(import('../pages/iframe-yrityslaina.vue' /* webpackChunkName: "pages/iframe-yrityslaina" */))
const _a3f70f38 = () => interopDefault(import('../pages/iframe-yrityslaina/yrityslainaheti.vue' /* webpackChunkName: "pages/iframe-yrityslaina/yrityslainaheti" */))
const _9bdea038 = () => interopDefault(import('../pages/jarjestelylaina.vue' /* webpackChunkName: "pages/jarjestelylaina" */))
const _3047551f = () => interopDefault(import('../pages/joustoluotto.vue' /* webpackChunkName: "pages/joustoluotto" */))
const _98b9595c = () => interopDefault(import('../pages/kilpailuta-lainat.vue' /* webpackChunkName: "pages/kilpailuta-lainat" */))
const _f3b8ee04 = () => interopDefault(import('../pages/kokemuksia.vue' /* webpackChunkName: "pages/kokemuksia" */))
const _5c456892 = () => interopDefault(import('../pages/lainaa.vue' /* webpackChunkName: "pages/lainaa" */))
const _4f70c6f1 = () => interopDefault(import('../pages/lainaa-1000-euroa.vue' /* webpackChunkName: "pages/lainaa-1000-euroa" */))
const _cc32e886 = () => interopDefault(import('../pages/lainaa-10000-euroa.vue' /* webpackChunkName: "pages/lainaa-10000-euroa" */))
const _7bc4555c = () => interopDefault(import('../pages/lainaa-11000-euroa.vue' /* webpackChunkName: "pages/lainaa-11000-euroa" */))
const _033b7bd8 = () => interopDefault(import('../pages/lainaa-15000-euroa.vue' /* webpackChunkName: "pages/lainaa-15000-euroa" */))
const _187c1404 = () => interopDefault(import('../pages/lainaa-20000-euroa.vue' /* webpackChunkName: "pages/lainaa-20000-euroa" */))
const _5d16e619 = () => interopDefault(import('../pages/lainaa-25000-euroa.vue' /* webpackChunkName: "pages/lainaa-25000-euroa" */))
const _4d9d603f = () => interopDefault(import('../pages/lainaa-30000-euroa.vue' /* webpackChunkName: "pages/lainaa-30000-euroa" */))
const _b10e6b00 = () => interopDefault(import('../pages/lainaa-40000-euroa.vue' /* webpackChunkName: "pages/lainaa-40000-euroa" */))
const _52302526 = () => interopDefault(import('../pages/lainaa-5000-euroa.vue' /* webpackChunkName: "pages/lainaa-5000-euroa" */))
const _015434c1 = () => interopDefault(import('../pages/lainaa-50000-euroa.vue' /* webpackChunkName: "pages/lainaa-50000-euroa" */))
const _5b2f9f02 = () => interopDefault(import('../pages/lainaa-60000-euroa.vue' /* webpackChunkName: "pages/lainaa-60000-euroa" */))
const _95e9ed7a = () => interopDefault(import('../pages/lainaa-70000-euroa.vue' /* webpackChunkName: "pages/lainaa-70000-euroa" */))
const _5d0828c9 = () => interopDefault(import('../pages/lainahakemus.vue' /* webpackChunkName: "pages/lainahakemus" */))
const _4524c44c = () => interopDefault(import('../pages/lainakumppanit.vue' /* webpackChunkName: "pages/lainakumppanit" */))
const _006f3c7e = () => interopDefault(import('../pages/lainavertailu.vue' /* webpackChunkName: "pages/lainavertailu" */))
const _81083b7e = () => interopDefault(import('../pages/luotto.vue' /* webpackChunkName: "pages/luotto" */))
const _2136271e = () => interopDefault(import('../pages/markkinointikielto.vue' /* webpackChunkName: "pages/markkinointikielto" */))
const _11ca8ac0 = () => interopDefault(import('../pages/mmkisat.vue' /* webpackChunkName: "pages/mmkisat" */))
const _0e38cafa = () => interopDefault(import('../pages/moottoripyoralaina.vue' /* webpackChunkName: "pages/moottoripyoralaina" */))
const _07fc499e = () => interopDefault(import('../pages/oma/index.vue' /* webpackChunkName: "pages/oma/index" */))
const _43986c0f = () => interopDefault(import('../pages/remonttilaina.vue' /* webpackChunkName: "pages/remonttilaina" */))
const _f7a8ff8a = () => interopDefault(import('../pages/sijoituslaina.vue' /* webpackChunkName: "pages/sijoituslaina" */))
const _d696c71e = () => interopDefault(import('../pages/smskielto.vue' /* webpackChunkName: "pages/smskielto" */))
const _635aee2e = () => interopDefault(import('../pages/talous/index.vue' /* webpackChunkName: "pages/talous/index" */))
const _1d5b7ba4 = () => interopDefault(import('../pages/tietoja-evasteista.vue' /* webpackChunkName: "pages/tietoja-evasteista" */))
const _17d8290e = () => interopDefault(import('../pages/venelaina.vue' /* webpackChunkName: "pages/venelaina" */))
const _6d7011c6 = () => interopDefault(import('../pages/yhdista-lainat.vue' /* webpackChunkName: "pages/yhdista-lainat" */))
const _76764abc = () => interopDefault(import('../pages/yhdistelylaina.vue' /* webpackChunkName: "pages/yhdistelylaina" */))
const _65649c16 = () => interopDefault(import('../pages/yhteistyokumppanit/index.vue' /* webpackChunkName: "pages/yhteistyokumppanit/index" */))
const _40a7101c = () => interopDefault(import('../pages/yleiset-ehdot.vue' /* webpackChunkName: "pages/yleiset-ehdot" */))
const _a9225304 = () => interopDefault(import('../pages/yrityksen-rahoitus.vue' /* webpackChunkName: "pages/yrityksen-rahoitus" */))
const _03e2dfb6 = () => interopDefault(import('../pages/yritys.vue' /* webpackChunkName: "pages/yritys" */))
const _0907740f = () => interopDefault(import('../pages/yrityslainaa-5000-euroa.vue' /* webpackChunkName: "pages/yrityslainaa-5000-euroa" */))
const _34ecde2c = () => interopDefault(import('../pages/yrityslainakumppanit.vue' /* webpackChunkName: "pages/yrityslainakumppanit" */))
const _35391c7a = () => interopDefault(import('../pages/yrityslainat.vue' /* webpackChunkName: "pages/yrityslainat" */))
const _bf4bb096 = () => interopDefault(import('../pages/yritysrahoitus.vue' /* webpackChunkName: "pages/yritysrahoitus" */))
const _b53bd5b6 = () => interopDefault(import('../pages/cc-new/login.vue' /* webpackChunkName: "pages/cc-new/login" */))
const _4930272b = () => interopDefault(import('../pages/talous/ala-hae-lainaa-mista-tahansa.vue' /* webpackChunkName: "pages/talous/ala-hae-lainaa-mista-tahansa" */))
const _665e321c = () => interopDefault(import('../pages/talous/auton-hankinnassa-voi-saastaa-reilusti-kilpailuttamalla.vue' /* webpackChunkName: "pages/talous/auton-hankinnassa-voi-saastaa-reilusti-kilpailuttamalla" */))
const _45a1d9ac = () => interopDefault(import('../pages/talous/essi-ja-jere-hyodynsivat-lainojen-korkokaton.vue' /* webpackChunkName: "pages/talous/essi-ja-jere-hyodynsivat-lainojen-korkokaton" */))
const _57eef482 = () => interopDefault(import('../pages/talous/haaveiletko-urheilureissusta.vue' /* webpackChunkName: "pages/talous/haaveiletko-urheilureissusta" */))
const _3cecea5c = () => interopDefault(import('../pages/talous/hakeako-yrityslaina-perinteisesti-pankista-vai-nopeasti-ilman-vakuuksia.vue' /* webpackChunkName: "pages/talous/hakeako-yrityslaina-perinteisesti-pankista-vai-nopeasti-ilman-vakuuksia" */))
const _aea98a8a = () => interopDefault(import('../pages/talous/kansainvaliset-digipankit-ovat-myllyttaneet-suomen-lainamarkkinat.vue' /* webpackChunkName: "pages/talous/kansainvaliset-digipankit-ovat-myllyttaneet-suomen-lainamarkkinat" */))
const _0fae9aa0 = () => interopDefault(import('../pages/talous/katso-5-kohdan-listaus-jolla-helpotat-taloutesi-hoitoa.vue' /* webpackChunkName: "pages/talous/katso-5-kohdan-listaus-jolla-helpotat-taloutesi-hoitoa" */))
const _e2c4c1b6 = () => interopDefault(import('../pages/talous/korkokatto-paattyy-joulukuussa.vue' /* webpackChunkName: "pages/talous/korkokatto-paattyy-joulukuussa" */))
const _015b7762 = () => interopDefault(import('../pages/talous/korkokatto-putosi-kymmeneen-prosenttiin.vue' /* webpackChunkName: "pages/talous/korkokatto-putosi-kymmeneen-prosenttiin" */))
const _4531eff6 = () => interopDefault(import('../pages/talous/korkokattoa-jatkettiin-syksyyn.vue' /* webpackChunkName: "pages/talous/korkokattoa-jatkettiin-syksyyn" */))
const _4b9e9088 = () => interopDefault(import('../pages/talous/kulutusluottojen-korot-laskivat-korkokaton-seurauksena.vue' /* webpackChunkName: "pages/talous/kulutusluottojen-korot-laskivat-korkokaton-seurauksena" */))
const _76058510 = () => interopDefault(import('../pages/talous/kulutusluottojen-korot-putosivat-selvasti.vue' /* webpackChunkName: "pages/talous/kulutusluottojen-korot-putosivat-selvasti" */))
const _6d53a522 = () => interopDefault(import('../pages/talous/kulutusluottojen-lakimuutos-sai-suomalaiset-liikkeelle.vue' /* webpackChunkName: "pages/talous/kulutusluottojen-lakimuutos-sai-suomalaiset-liikkeelle" */))
const _2ee95cf8 = () => interopDefault(import('../pages/talous/kulutusluottojen-saannot-uusiksi-nain-hyodyt-korkokatosta.vue' /* webpackChunkName: "pages/talous/kulutusluottojen-saannot-uusiksi-nain-hyodyt-korkokatosta" */))
const _3f1644fe = () => interopDefault(import('../pages/talous/lainat-kannattaa-yhdistaa-heti.vue' /* webpackChunkName: "pages/talous/lainat-kannattaa-yhdistaa-heti" */))
const _406cba66 = () => interopDefault(import('../pages/talous/lainojen-yhdistaminen-kannatti.vue' /* webpackChunkName: "pages/talous/lainojen-yhdistaminen-kannatti" */))
const _16400d60 = () => interopDefault(import('../pages/talous/mita-tekisit-200-eurolla.vue' /* webpackChunkName: "pages/talous/mita-tekisit-200-eurolla" */))
const _67c43316 = () => interopDefault(import('../pages/talous/miten-yrityslainoja-on-jarkevin-ja-helpoin-vertailla.vue' /* webpackChunkName: "pages/talous/miten-yrityslainoja-on-jarkevin-ja-helpoin-vertailla" */))
const _e0fe2de6 = () => interopDefault(import('../pages/talous/nain-loydat-luotettavan-lainan-edullisella-korolla.vue' /* webpackChunkName: "pages/talous/nain-loydat-luotettavan-lainan-edullisella-korolla" */))
const _4d80c021 = () => interopDefault(import('../pages/talous/nain-selviat-kasvun-haasteista-ja-yllattavista-tilanteista.vue' /* webpackChunkName: "pages/talous/nain-selviat-kasvun-haasteista-ja-yllattavista-tilanteista" */))
const _92ed0cee = () => interopDefault(import('../pages/talous/oletko-jo-kokeillut-lainojen-kilpailuttamista-verkossa.vue' /* webpackChunkName: "pages/talous/oletko-jo-kokeillut-lainojen-kilpailuttamista-verkossa" */))
const _3294f130 = () => interopDefault(import('../pages/talous/petri-yhdisti-pienet-lainansa-ja-hammastyi.vue' /* webpackChunkName: "pages/talous/petri-yhdisti-pienet-lainansa-ja-hammastyi" */))
const _03896fcc = () => interopDefault(import('../pages/talous/piia-saastaa-yli-300-euroa-kuukaudessa-laskeneen-korkokaton-ansiosta.vue' /* webpackChunkName: "pages/talous/piia-saastaa-yli-300-euroa-kuukaudessa-laskeneen-korkokaton-ansiosta" */))
const _26b578e6 = () => interopDefault(import('../pages/talous/pitkaan-odotettu-reissu-edessa-ja-rahat-vahissa.vue' /* webpackChunkName: "pages/talous/pitkaan-odotettu-reissu-edessa-ja-rahat-vahissa" */))
const _005a2f14 = () => interopDefault(import('../pages/talous/teija-yhdisti-lainansa-ja-saastaa-nyt-kuluissa.vue' /* webpackChunkName: "pages/talous/teija-yhdisti-lainansa-ja-saastaa-nyt-kuluissa" */))
const _52721734 = () => interopDefault(import('../pages/talous/vakuudettomien-lainojen-korkoja-rajoittava-laki-astui-voimaan.vue' /* webpackChunkName: "pages/talous/vakuudettomien-lainojen-korkoja-rajoittava-laki-astui-voimaan" */))
const _3a3444ac = () => interopDefault(import('../pages/yhteistyokumppanit/_company.vue' /* webpackChunkName: "pages/yhteistyokumppanit/_company" */))
const _b108e32c = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/application-stored",
    component: _7c095558,
    name: "application-stored"
  }, {
    path: "/autolaina",
    component: _32ad56e0,
    name: "autolaina"
  }, {
    path: "/autorahoitus",
    component: _553c4874,
    name: "autorahoitus"
  }, {
    path: "/cc-new",
    component: _488b1be4,
    name: "cc-new",
    children: [{
      path: "kuluttaja",
      component: _57fa3284,
      name: "cc-new-index-kuluttaja"
    }, {
      path: "yritys",
      component: _7502acef,
      name: "cc-new-index-yritys"
    }]
  }, {
    path: "/etutuoterahoitus",
    component: _5a0d4230,
    name: "etutuoterahoitus"
  }, {
    path: "/identiteettivakuutus",
    component: _bded8810,
    name: "identiteettivakuutus"
  }, {
    path: "/iframe",
    component: _70ab573c,
    name: "iframe",
    children: [{
      path: "leadgroup",
      component: _4b916590,
      name: "iframe-leadgroup"
    }]
  }, {
    path: "/iframe-yrityslaina",
    component: _6ce089d8,
    name: "iframe-yrityslaina",
    children: [{
      path: "yrityslainaheti",
      component: _a3f70f38,
      name: "iframe-yrityslaina-yrityslainaheti"
    }]
  }, {
    path: "/jarjestelylaina",
    component: _9bdea038,
    name: "jarjestelylaina"
  }, {
    path: "/joustoluotto",
    component: _3047551f,
    name: "joustoluotto"
  }, {
    path: "/kilpailuta-lainat",
    component: _98b9595c,
    name: "kilpailuta-lainat"
  }, {
    path: "/kokemuksia",
    component: _f3b8ee04,
    name: "kokemuksia"
  }, {
    path: "/lainaa",
    component: _5c456892,
    name: "lainaa"
  }, {
    path: "/lainaa-1000-euroa",
    component: _4f70c6f1,
    name: "lainaa-1000-euroa"
  }, {
    path: "/lainaa-10000-euroa",
    component: _cc32e886,
    name: "lainaa-10000-euroa"
  }, {
    path: "/lainaa-11000-euroa",
    component: _7bc4555c,
    name: "lainaa-11000-euroa"
  }, {
    path: "/lainaa-15000-euroa",
    component: _033b7bd8,
    name: "lainaa-15000-euroa"
  }, {
    path: "/lainaa-20000-euroa",
    component: _187c1404,
    name: "lainaa-20000-euroa"
  }, {
    path: "/lainaa-25000-euroa",
    component: _5d16e619,
    name: "lainaa-25000-euroa"
  }, {
    path: "/lainaa-30000-euroa",
    component: _4d9d603f,
    name: "lainaa-30000-euroa"
  }, {
    path: "/lainaa-40000-euroa",
    component: _b10e6b00,
    name: "lainaa-40000-euroa"
  }, {
    path: "/lainaa-5000-euroa",
    component: _52302526,
    name: "lainaa-5000-euroa"
  }, {
    path: "/lainaa-50000-euroa",
    component: _015434c1,
    name: "lainaa-50000-euroa"
  }, {
    path: "/lainaa-60000-euroa",
    component: _5b2f9f02,
    name: "lainaa-60000-euroa"
  }, {
    path: "/lainaa-70000-euroa",
    component: _95e9ed7a,
    name: "lainaa-70000-euroa"
  }, {
    path: "/lainahakemus",
    component: _5d0828c9,
    name: "lainahakemus"
  }, {
    path: "/lainakumppanit",
    component: _4524c44c,
    name: "lainakumppanit"
  }, {
    path: "/lainavertailu",
    component: _006f3c7e,
    name: "lainavertailu"
  }, {
    path: "/luotto",
    component: _81083b7e,
    name: "luotto"
  }, {
    path: "/markkinointikielto",
    component: _2136271e,
    name: "markkinointikielto"
  }, {
    path: "/mmkisat",
    component: _11ca8ac0,
    name: "mmkisat"
  }, {
    path: "/moottoripyoralaina",
    component: _0e38cafa,
    name: "moottoripyoralaina"
  }, {
    path: "/oma",
    component: _07fc499e,
    name: "oma"
  }, {
    path: "/remonttilaina",
    component: _43986c0f,
    name: "remonttilaina"
  }, {
    path: "/sijoituslaina",
    component: _f7a8ff8a,
    name: "sijoituslaina"
  }, {
    path: "/smskielto",
    component: _d696c71e,
    name: "smskielto"
  }, {
    path: "/talous",
    component: _635aee2e,
    name: "talous"
  }, {
    path: "/tietoja-evasteista",
    component: _1d5b7ba4,
    name: "tietoja-evasteista"
  }, {
    path: "/venelaina",
    component: _17d8290e,
    name: "venelaina"
  }, {
    path: "/yhdista-lainat",
    component: _6d7011c6,
    name: "yhdista-lainat"
  }, {
    path: "/yhdistelylaina",
    component: _76764abc,
    name: "yhdistelylaina"
  }, {
    path: "/yhteistyokumppanit",
    component: _65649c16,
    name: "yhteistyokumppanit"
  }, {
    path: "/yleiset-ehdot",
    component: _40a7101c,
    name: "yleiset-ehdot"
  }, {
    path: "/yrityksen-rahoitus",
    component: _a9225304,
    name: "yrityksen-rahoitus"
  }, {
    path: "/yritys",
    component: _03e2dfb6,
    name: "yritys"
  }, {
    path: "/yrityslainaa-5000-euroa",
    component: _0907740f,
    name: "yrityslainaa-5000-euroa"
  }, {
    path: "/yrityslainakumppanit",
    component: _34ecde2c,
    name: "yrityslainakumppanit"
  }, {
    path: "/yrityslainat",
    component: _35391c7a,
    name: "yrityslainat"
  }, {
    path: "/yritysrahoitus",
    component: _bf4bb096,
    name: "yritysrahoitus"
  }, {
    path: "/cc-new/login",
    component: _b53bd5b6,
    name: "cc-new-login"
  }, {
    path: "/talous/ala-hae-lainaa-mista-tahansa",
    component: _4930272b,
    name: "talous-ala-hae-lainaa-mista-tahansa"
  }, {
    path: "/talous/auton-hankinnassa-voi-saastaa-reilusti-kilpailuttamalla",
    component: _665e321c,
    name: "talous-auton-hankinnassa-voi-saastaa-reilusti-kilpailuttamalla"
  }, {
    path: "/talous/essi-ja-jere-hyodynsivat-lainojen-korkokaton",
    component: _45a1d9ac,
    name: "talous-essi-ja-jere-hyodynsivat-lainojen-korkokaton"
  }, {
    path: "/talous/haaveiletko-urheilureissusta",
    component: _57eef482,
    name: "talous-haaveiletko-urheilureissusta"
  }, {
    path: "/talous/hakeako-yrityslaina-perinteisesti-pankista-vai-nopeasti-ilman-vakuuksia",
    component: _3cecea5c,
    name: "talous-hakeako-yrityslaina-perinteisesti-pankista-vai-nopeasti-ilman-vakuuksia"
  }, {
    path: "/talous/kansainvaliset-digipankit-ovat-myllyttaneet-suomen-lainamarkkinat",
    component: _aea98a8a,
    name: "talous-kansainvaliset-digipankit-ovat-myllyttaneet-suomen-lainamarkkinat"
  }, {
    path: "/talous/katso-5-kohdan-listaus-jolla-helpotat-taloutesi-hoitoa",
    component: _0fae9aa0,
    name: "talous-katso-5-kohdan-listaus-jolla-helpotat-taloutesi-hoitoa"
  }, {
    path: "/talous/korkokatto-paattyy-joulukuussa",
    component: _e2c4c1b6,
    name: "talous-korkokatto-paattyy-joulukuussa"
  }, {
    path: "/talous/korkokatto-putosi-kymmeneen-prosenttiin",
    component: _015b7762,
    name: "talous-korkokatto-putosi-kymmeneen-prosenttiin"
  }, {
    path: "/talous/korkokattoa-jatkettiin-syksyyn",
    component: _4531eff6,
    name: "talous-korkokattoa-jatkettiin-syksyyn"
  }, {
    path: "/talous/kulutusluottojen-korot-laskivat-korkokaton-seurauksena",
    component: _4b9e9088,
    name: "talous-kulutusluottojen-korot-laskivat-korkokaton-seurauksena"
  }, {
    path: "/talous/kulutusluottojen-korot-putosivat-selvasti",
    component: _76058510,
    name: "talous-kulutusluottojen-korot-putosivat-selvasti"
  }, {
    path: "/talous/kulutusluottojen-lakimuutos-sai-suomalaiset-liikkeelle",
    component: _6d53a522,
    name: "talous-kulutusluottojen-lakimuutos-sai-suomalaiset-liikkeelle"
  }, {
    path: "/talous/kulutusluottojen-saannot-uusiksi-nain-hyodyt-korkokatosta",
    component: _2ee95cf8,
    name: "talous-kulutusluottojen-saannot-uusiksi-nain-hyodyt-korkokatosta"
  }, {
    path: "/talous/lainat-kannattaa-yhdistaa-heti",
    component: _3f1644fe,
    name: "talous-lainat-kannattaa-yhdistaa-heti"
  }, {
    path: "/talous/lainojen-yhdistaminen-kannatti",
    component: _406cba66,
    name: "talous-lainojen-yhdistaminen-kannatti"
  }, {
    path: "/talous/mita-tekisit-200-eurolla",
    component: _16400d60,
    name: "talous-mita-tekisit-200-eurolla"
  }, {
    path: "/talous/miten-yrityslainoja-on-jarkevin-ja-helpoin-vertailla",
    component: _67c43316,
    name: "talous-miten-yrityslainoja-on-jarkevin-ja-helpoin-vertailla"
  }, {
    path: "/talous/nain-loydat-luotettavan-lainan-edullisella-korolla",
    component: _e0fe2de6,
    name: "talous-nain-loydat-luotettavan-lainan-edullisella-korolla"
  }, {
    path: "/talous/nain-selviat-kasvun-haasteista-ja-yllattavista-tilanteista",
    component: _4d80c021,
    name: "talous-nain-selviat-kasvun-haasteista-ja-yllattavista-tilanteista"
  }, {
    path: "/talous/oletko-jo-kokeillut-lainojen-kilpailuttamista-verkossa",
    component: _92ed0cee,
    name: "talous-oletko-jo-kokeillut-lainojen-kilpailuttamista-verkossa"
  }, {
    path: "/talous/petri-yhdisti-pienet-lainansa-ja-hammastyi",
    component: _3294f130,
    name: "talous-petri-yhdisti-pienet-lainansa-ja-hammastyi"
  }, {
    path: "/talous/piia-saastaa-yli-300-euroa-kuukaudessa-laskeneen-korkokaton-ansiosta",
    component: _03896fcc,
    name: "talous-piia-saastaa-yli-300-euroa-kuukaudessa-laskeneen-korkokaton-ansiosta"
  }, {
    path: "/talous/pitkaan-odotettu-reissu-edessa-ja-rahat-vahissa",
    component: _26b578e6,
    name: "talous-pitkaan-odotettu-reissu-edessa-ja-rahat-vahissa"
  }, {
    path: "/talous/teija-yhdisti-lainansa-ja-saastaa-nyt-kuluissa",
    component: _005a2f14,
    name: "talous-teija-yhdisti-lainansa-ja-saastaa-nyt-kuluissa"
  }, {
    path: "/talous/vakuudettomien-lainojen-korkoja-rajoittava-laki-astui-voimaan",
    component: _52721734,
    name: "talous-vakuudettomien-lainojen-korkoja-rajoittava-laki-astui-voimaan"
  }, {
    path: "/yhteistyokumppanit/:company",
    component: _3a3444ac,
    name: "yhteistyokumppanit-company"
  }, {
    path: "/",
    component: _b108e32c,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
