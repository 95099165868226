<template>
  <div>
    <main-hero
      title="Lainojen yhdistäminen - Yhdistä lainat ja säästä!"
      :subtitle="{ desktop: 'Lainojen yhdistäminen voi tuoda sinulle merkittävää säästöä ja helpottaa arkeasi.', touch: 'Voit yhdistää lainoja jopa 60 000 euroon saakka' }"
      side-title="Täytä hakemus ja vertaa!"
      :side-boxes="sideBoxes"
      :breadcrumb="{'Yhdistä lainat': ''}"
    />
    <section class="hero has-background-rlgray">
      <div class="hero-body">
        <div class="container">
          <h2 class="title has-text-white is-size-4 has-text-centered">
            Lainojen yhdistäminen kannattaa!
          </h2>
          <div id="giosg-bot" />
        </div>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <lazy-hydrate when-visible>
          <loan-steps />
        </lazy-hydrate>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <div class="content">
          <img
            class="is-hidden-touch"
            src="~/assets/img/subpage/yhdista-lainasi.jpg"
            alt="Yhdistä lainat ja säästä"
            style="float: right; margin: 0 0 5rem 5rem; max-width: 31.25rem;"
          >
          <h2>
            Näin hyödyt, kun yhdistät monta lainaasi yhdeksi!
          </h2>
          <p>
            Joudutko kuukausittain maksamaan lainanlyhennyksiä useille eri lainanmyöntäjille? Jos tilanne on tämä, kannattaa harkita, olisiko lainat viisainta yhdistää.
          </p>
          <p>
            Lainojen yhdistäminen tarkoittaa esimerkiksi useiden eri pienlainojen, kulutusluottojen ja mahdollisten luottokorttivelkojen yhdistämistä yhdeksi isommaksi lainaksi.
          </p>
          <p>
            Kun yhdistät lainasi, voit hyötyä siitä muun muassa seuraavilla tavoilla:
          </p>
          <ul>
            <li>
              Voit säästät selvää rahaa, kun et joudu maksamaan tilin- ja lainanhoitokuluja sekä laskutuslisiä erikseen moneen eri paikkaan. Jo pelkistä tilinhoitomaksuista saattaa kertyä kuukaudessa merkittävä summa, mikäli lainoja on useita.
            </li>
            <li>
              Lainat yhdistämällä voit löytää edullisemman koron ja pidemmän takaisinmaksuajan lainallesi. Tämä tuo joustavuutta raha-asioiden hoitoon.
            </li>
            <li>
              Kun maksettavana on vain yksi lasku, säästät myös aikaasi – et joudu muistamaan erikseen useita eräpäiviä ja käyttämään aikaa monen eri laskun maksamiseen.
            </li>
            <li>
              Oman talouden hoitaminen ja seuranta helpottuu, kun lyhennettävänä on vain yksi laina.
            </li>
          </ul>
          <p>
            Lainojen yhdistämisen hyödyt ovat selkeät, mutta kuinka yhdistäminen käytännössä hoidetaan?
          </p>
          <p>
            Tarkastellaan seuraavaksi sitä, miten me Rahalaitoksella voimme auttaa sinua yhdistämään lainasi ilmaiseksi yhdellä hakemuksella – vain muutamassa minuutissa.
          </p>
          <h2>
            Yhdistä lainat Rahalaitoksen avulla – näin se onnistuu
          </h2>
          <p>
            Rahalaitos on suomalainen lainanvälittäjä, joka kilpailuttaa eri lainanmyöntäjien ja rahoituslaitosten 500-60 000 euron suuruiset lainat. Kauttamme voit kuitenkin myös yhdistää vanhoja lainojasi aina 60 000 euroon asti. Riittää, kun merkitset rastin lainahakemuksestamme löytyvään ruutuun, jossa kysytään, haluatko yhdistää vanhoja lainoja.
          </p>
          <p>
            Laina-ajan pääset valitsemaan 1-15 vuoden väliltä. Lyhyempi laina-aika luonnollisesti madaltaa lainan kustannuksia. Voit toki maksaa lainan pois myös suunniteltua aiemmin, jos rahatilanteesi onkin jossain vaiheessa ennakoitua parempi. Tästä ei koidu sinulle lisäkuluja, ja korkoa maksat tietysti vain siltä ajalta, kun lainaa on käytössäsi.
          </p>
          <h3>
            Kenelle tarjoamme lainojen yhdistämistä?
          </h3>
          <p>
            Voit yhdistää vanhoja lainojasi palvelumme avulla, jos täytät seuraavat ehdot:
          </p>
          <ul>
            <li>
              Olet iältäsi vähintään 20.
            </li>
            <li>
              Kuukausitulosi ylittävät 600 euroa.
            </li>
            <li>
              Sinulla ei ole julkisia maksuhäiriömerkintöjä.
            </li>
          </ul>
          <p>
            Mikäli täytit yllä olevat kriteerit, voit hakea tarjoukset lainojen yhdistämisestä kauttamme ilmaiseksi. Hakeminen on riskitöntä, eli lainahakemuksen täyttäminen ei sido sinua mihinkään. Voit tehdä vertailua rauhassa ja sen jälkeen päättää, hyväksytkö jonkin tarjouksista vai et.
          </p>
          <p>
            Lainahakemuksen täyttäminen vie aikaa muutaman minuutin, minkä jälkeen saat heti päätöksen siitä, voitko yhdistää lainat avullamme vai et. Jos päätös on kannaltasi myönteinen, saat ensimmäiset tarjoukset yhteistyökumppaneiltamme usein vielä saman päivän aikana.
          </p>
          <p>
            Vanhojen lainojen yhdistäminen onnistuu kauttamme viikon jokaisena päivänä vuorokauden ympäri.
          </p>
          <h2>
            Miten löytää paras keino yhdistää lainat?
          </h2>
          <p>
            Paras lainojen yhdistäminen voi tarkoittaa eri ihmisille eri asioita ja paras yhdistelylaina löytyy vain kilpailuttamalla useita pankkeja. Jokaisella pankilla on omat lainakorot, tilinhoitomaksut sekä muut kustannukset, joita on hyvä vertailla, kun haluat yhdistää lainasi.
          </p>
          <p>
            Joillekin paras lainojen yhdistäminen voi myös tarkoittaa sitä, että lainantarjoaja mahdollistaa joustavan takaisinmaksun. Lainaehdot on siis syytä myös lukea huolellisesti läpi, ennen kuin valitsee lainatarjouksen.
          </p>
          <h3>
            Kuinka yhdistää lainat ja miten se käytännössä tapahtuu?
          </h3>
          <p>
            Kun yhdistät lainasi, nykyiset lainat tai luottokorttivelat maksetaan kerralla pois. Lyhennettäväksi jää useiden lainojen sijaan yksi yhdistetty laina, joka on sovitettu omaan talouteen sopivaksi.
          </p>
          <p>
            Toimi näin:
          </p>
          <ol>
            <li>
              Täytä maksuton hakemus Rahalaitoksen sivuilla
            </li>
            <li>
              Kilpailutamme sinulle edullisimman yhdistelylainan useilla lainantarjoajilla
            </li>
            <li>
              Vertaile tarjouksia palvelussamme ja valitse itsellesi sopivin laina
            </li>
            <li>
              Allekirjoita lainasopimus pankkitunnuksilla
            </li>
            <li>
              Nykyiset lainat tai luotot maksetaan pois joko itse tai pankin toimesta
            </li>
            <li>
              Lyhennä jatkossa vain yhtä edullisempaa lainaa
            </li>
          </ol>
          <p>
            Lainojen yhdistäminen tarkoittaa nykyisten lainojen rahoittamista uudelleen paremmilla ehdoilla. Lainoja yhdistettäessä velan määrä pysyy ennallaan, mutta sen ehdot muuttuvat aiempia maksusopimuksia edullisimmiksi.
          </p>
          <p>
            Kilpailuta <nuxt-link to="/yhdistelylaina">yhdistelylaina</nuxt-link> nyt!
          </p>
        </div>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <faq />
      </div>
    </section>
    <section class="section">
      <div class="container">
        <div class="content">
          <h3>
            Lainojen yhdistäminen on yksi keino aloittaa säästäminen
          </h3>
          <p>
            Kun yhdistät lainat sinulle jää käyttöön kuukaudessa enemmän rahaa. Yksi hyvä kohde säästyneiden rahojen käyttöön on aloittaa kuukausisäästäminen. Lainojen yhdistäminen on yksi osa oman talouden suunnittelua. Pitkällä tähtäimellä kannattaa lainojen maksamisen lisäksi pyrkiä saamaan säästöön rahaa yllättäviä tilanteita varten. Nyt kannattaa hyödyntää mahdollisuus säästää samalla kun lainakulut pienenevät lainat yhdistämällä.
          </p>
          <p>
            Pienikin kuukausittain säästöön laitettu summa kasvaa ajan myötä, kun sijoitat sen järkevästi. Tutustu eri mahdollisuuksiin sijoittaa säästämäsi raha tarkasti. Tarjolla on paljon vaihtoehtoja kuten säästötilejä, rahastoja ja osakkeita, joihin voi sijoittaa kuukausittain.
          </p>
          <p>
            Säästäminen on monella tapaa hyväksi taloudellesi, joka lisää hyötyäsi, jonka saat lainojen yhdistämisestä. Oman talouden suunnittelussa on hyvä punnita erilaiset vaihtoehdot huolella, jotta löytää juuri itsellesi sopivan vaihtoehdon.
          </p>
          <p>
            Yhdistä lainat nyt, sillä jokainen kuukausi säästyneitä lainakuluja kannattaa hyödyntää.
          </p>
          <h3>
            Yhdistä lainat ja saat paremman kokonaiskuvan taloudestasi
          </h3>
          <p>
            Nykyään on tavallista hankkia pieniä ja suuria lainoja erilaisiin tarpeisiin useilta eri lainantarjoajilta. Ajan myötä erilaiset luotot, luottokortit ja osamaksusopimukset tulevat kuitenkin yhdessä helposti kalliiksi.
          </p>
          <p>
            Kun sinulle kertyy useita lainoja, on työlästä pysyä kartalla lainojen kuluista ja koroista. Kun yhdistät lainat, sinun ei tarvitse kantaa huolta kuin yhdestä lainasta. Tämä tarkoittaa vain yhtä eräpäivää, yhtä laskua, yhtä korkoa sekä yksiä lainakustannuksia. Tämä auttaa sinua saamaan helpommin kokonaiskuvan lainasi ja koko taloutesi todellisista kustannuksista.
          </p>
          <p>
            Mikäli keräät lainat yhdeksi lainaksi, sinun on helpompi näyttää pankeille ja luotonmyöntäjille taloutesi olevan hyvässä kunnossa. Tämä on käytännöllistä mikäli olet myöhemmin hakemassa lainaa esimerkiksi asunnon hankintaa varten.
          </p>
          <p>
            Yhdistä lainat nyt Rahalaitoksen avulla. Saat kilpailutuspalvelumme avulla useita tarjouksia lainojen yhdistämiseen, eikä sinun tarvitse käyttää aikaasi kuin muutama minuutti lainahakemuksen tekemiseen sivuillamme.
          </p>
          <h3>
            Mitä lainoja ja luottoja voi ja kannattaa yhdistää?
          </h3>
          <p>
            Lainantarve tulee useimmin eteen kodin isojen hankintojen yhteydessä. Yleisimpiä tarpeita lainoille ovat auton hankinta, kodin remontit, veneet tai muut suuremmat harrastuksiin liittyvät hankinnat sekä kodinkoneet tai huonekalut. Lisäksi rikki menneiden kodinkoneiden korvaamiseen saatetaan toisinaan tarvita lainaa.
          </p>
          <p>
            Lainoja ja erilaisia osamaksusopimuksia saattaa yllämainituissa hankinnoissa kertyä helposti ja lopputuloksena voi huomata, että maksaa korkeakorkoista vakuudetonta lainaa sekä erilaisia maksuja jokaisesta luotosta erikseen. Nämä ovat selkeitä mahdollisuuksia säästää yhdistämällä lainat.
          </p>
          <p>
            Kulutusluottojen ja lainojen lisäksi kannattaa lainat yhdistettäessä tarkistaa olemassa olevien luottokorttien saldot ja osamaksusopimukset. Usein yhdistelylainan korko on alhaisempi kuin luottokorteista ja osamaksuista maksettavat korot.
          </p>
          <p>
            Kun olet kartoittanut kaikkien nykyisten lainojen määrän ja kulut sekä korot, laske summa yhteen ja lähettää lainahakemus Rahalaitokselle. Tämän jälkeen voit rauhassa vertailla saamiasi lainatarjouksia ja katsoa olisiko sinunkin kannattavaa yhdistää lainasi.
          </p>
          <h3>
            Jäikö sinulle kysyttävää lainojen yhdistämisestä?
          </h3>
          <p>
            Mikäli jokin lainahakemuksen täyttämiseen tai lainojen yhdistelyyn liittyvä asia askarruttaa, voit aina ottaa yhteyttä asiakaspalveluumme.
          </p>
          <p>
            Saat meidät kiinni niin puhelimitse kuin myös sähköpostin tai verkkosivuiltamme löytyvän yhteydenottolomakkeen avulla. Palvelemme numerossa (09) 2311 3670 maanantaista perjantaihin kello 8.00-16.00. Sähköpostitiedustelut otamme vastaan osoitteeseen asiakaspalvelu@rahalaitos.fi.
          </p>
          <p>
            Jos kysyttävää ei jäänyt, täytä lainahakemuksemme nyt ja yhdistä vanhat lainasi yhteen nippuun – voit säästää sekä aikaa että rahaa!
          </p>
          <p>
            Voit myös lukea asiakkaiden kokemuksia Rahalaitoksen palvelusta sivulta <nuxt-link to="/kokemuksia">kokemuksia</nuxt-link>.
          </p>
        </div>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <lazy-hydrate when-visible>
          <subpages />
        </lazy-hydrate>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <lazy-hydrate when-visible>
          <banks />
        </lazy-hydrate>
      </div>
    </section>
  </div>
</template>

<script>
import LazyHydrate from 'vue-lazy-hydration'

export default {
  name: 'YhdistaLainasi',
  components: {
    LazyHydrate,
    banks: () => import( '~/components/layout/banks' ),
    faq: () => import( '~/components/layout/pages/yhdistaFaq' ),
    loanSteps: () => import( '~/components/layout/loanSteps' ),
    mainHero: () => import( '~/components/layout/mainHero' ),
    subpages: () => import( '~/components/layout/subpages' )
  },
  data () {
    return {
      sideBoxes: [
        {
          icon: () => import( '~/assets/img/icons/banks.svg?inline' ),
          title: 'Pienennä',
          subtitle: 'lainanhoito-<br>kulujasi'
        },
        {
          icon: () => import( '~/assets/img/icons/time.svg?inline' ),
          title: 'Helpota talouden',
          subtitle: 'hallintaa'
        },
        {
          icon: () => import( '~/assets/img/icons/money.svg?inline' ),
          title: 'Yhdistä lainoja jopa',
          subtitle: '60 000 €'
        }
      ]
    }
  },
  async fetch ( { store } ) {
    await store.commit( 'loanApplication/MERGE_TO_FORM', { yhdista_lainoja: true } )
    await store.dispatch( 'subpage/setPages', [
      {
        title: 'Kilpailuta lainat',
        subtitle: 'Säästät selvää rahaa',
        link: '/kilpailuta-lainat'
      },
      {
        title: 'Lainaa ilman takaajia',
        subtitle: 'Haaveista totta nopeasti ja helposti',
        link: '/lainaa-ilman-takaajia'
      },
      {
        title: 'Kilpailuta pohjoismaiset pankit',
        subtitle: 'Vertaile ja katso mistä saat edullisimman lainan',
        link: '/talous/suomalainen-ruotsalainen-vai-norjalainen-pankki',
        image: '/cms/uploads/large_photo_1538332576228_eb5b4c4de6f5_3bdf11ac3a.jpg'
      }
    ] )
  },
  head () {
    return {
      title: 'Lainojen yhdistäminen - Yhdistä lainat | Rahalaitos',
      meta: [
        { hid: 'description', name: 'description', content: 'Yhdistä vanhat lainasi yhdeksi Rahalaitoksen kautta ja säästä selvää rahaa joka kuukausi. Voit myös hakea uutta lainaa samalla hakemuksella - kokeile heti!' }
      ],
      link: [
        { rel: 'canonical', href: 'https://www.rahalaitos.fi/yhdista-lainat' }
      ]
    }
  }
}
</script>
