import Vue from 'vue'
import VueGtag, { bootstrap } from 'vue-gtag'

export default ( { $config, app, req, route }, inject ) => {
  // init settings
  const options = {
    config: {
      id: $config.RELEASE_STAGE === 'production'
        ? 'G-7DQJQPZKZS'
        : 'G-CNYPBY86W2'
    },
    bootstrap: true
  }

  // Should we disable google analytics, even if consent is given?
  const host = req ? req.headers.host : window.location.host.split( ':' )[0]
  const isWL = ['hakemus', 'app', 'lainakone', 'kulutusluotot', 'hae', 'lainahakemus'].includes(
    host.split( '.' )[0]
  )

  // disable if statistics consent not given.
  if ( isWL || route.path.startsWith( '/cc-new' ) ) {
    options.bootstrap = false
  }

  // Watch for cookiebot consent.
  window.addEventListener( 'CookiebotOnAccept', function ( _e ) {
    if ( !window.Cookiebot.consent.statistics ) {
      bootstrap()
    }
  }, false )

  // Inject analytics
  Vue.use( VueGtag, options, app.router )
  inject( 'gtag', Vue.$gtag )
}
