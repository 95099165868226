<template>
  <div>
    <navbar :is-spaced="false" />
    <nuxt-child :site-name="name" />
    <section v-if="$route.path === '/'" class="section is-small">
      <div class="container">
        <loan-application />
      </div>
    </section>
    <lazy-hydrate ssr-only>
      <footer-comp />
    </lazy-hydrate>
  </div>
</template>

<script>
import LazyHydrate from 'vue-lazy-hydration'
import _ from 'lodash'
import isDuplicate from '~/utils/Application/isDuplicate'

export default {
  components: {
    LazyHydrate,
    footerComp: () => import( '~/whitelabels/components/footer' ),
    loanApplication: () => import( '~/components/loanApplication' ),
    navbar: () => import( '~/whitelabels/components/navbar' )
  },
  layout: 'whitelabel',
  async asyncData ( { route, redirect, $logger, store } ) {
    // Redirect when duplicate applicant based on email.
    // Must also include duplicate query param in url so we know where to redirect.
    // Only called on server side
    if ( process.server ) {
      const query = route.query
      if ( query.email && query.duplicate ) {
        const result = await isDuplicate( {
          email: query.email,
          location: 'easyraha'
        }, $logger )
        if ( _.has( result, 'ActiveUntil' ) && result.ActiveUntil !== null ) {
          redirect( query.duplicate )
        }
      }
    }
    // Fill application fields
    store.dispatch( 'loanApplication/merge', route.query )
  },
  data () {
    return {
      name: 'Easyraha',
      domain: 'easyraha.fi'
    }
  },
  head () {
    const desc =
      'Easyraha lainahakemuksella voit hakea lainaa jopa 60 000 € asti.'
    const theme = '#374a6a'
    return {
      title: `${this.name} | Lainahakemus`,
      meta: [
        { hid: 'description', name: 'description', content: desc },
        {
          hid: 'og:title',
          name: 'og:title',
          property: 'og:title',
          content: this.name
        },
        {
          hid: 'og:site_name',
          name: 'og:site_name',
          property: 'og:site_name',
          content: this.name
        },
        {
          hid: 'og:description',
          name: 'og:description',
          property: 'og:description',
          content: desc
        },
        {
          hid: 'apple-mobile-web-app-title',
          name: 'apple-mobile-web-app-title',
          content: this.name
        },
        {
          hid: 'theme-color',
          name: 'theme-color',
          content: theme
        }
      ],
      link: [
        {
          rel: 'icon',
          type: 'image/x-icon',
          href: require( '~/assets/whitelabels/easyraha/favicon.png' )
        }
      ]
    }
  },
  mounted () {
    // Remove all params
    if ( window.location.search ) {
      window.history.replaceState( {}, document.title, window.location.origin )
    }
  }
}
</script>

<style lang="scss">
@import "~assets/whitelabels/scss/_variables.scss";

$wl-primary: #374a6a;
$wl-body-background: #f4f6fa;

$wl-navbar-background: #374a6a;
$wl-navbar-logo-height: 5rem;

$wl-button-background: #06ac75;

$wl-footer-background: #374a6a;
$wl-footer-color: #fff;

@import "~assets/whitelabels/scss/styles.scss";

.whitelabel.easyraha {
  @include whitelabel;
}
</style>
