<template>
  <div :class="`page-${$route.name}`">
    <navbar />
    <nuxt />
    <lazy-hydrate ssr-only>
      <footer-comp />
    </lazy-hydrate>
  </div>
</template>

<script>
import LazyHydrate from 'vue-lazy-hydration'

export default {
  components: {
    LazyHydrate,
    footerComp: () => import( '~/components/layout/footer' ),
    navbar: () => import( '~/components/layout/navbar' )
  },
  head () {
    const result = {
      link: [
        { rel: 'canonical', href: 'https://www.rahalaitos.fi' + this.$route.path }
      ],
      bodyAttrs: {
        class: this.$exp.$classes.join( ' ' )
      }
    }

    if ( ['www2.rahalaitos.fi', 'rahalaitos-v2.local'].includes( this.$domain ) ) {
      result.meta = [{ hid: 'robots', name: 'robots', content: 'noindex' }]
    }

    return result
  }
}
</script>
